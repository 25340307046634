import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import "../../index.css";
function Loader() {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <>
      <div className="loader">
        <Spin indicator={antIcon} />
        <p className="mt-4">loading</p>
      </div>
    </>
  );
}

export default Loader;