import React from "react";
import {
	FormOutlined,
	UserOutlined,
	AppstoreOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme, Popover } from "antd";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../components/Loader/Loader";

function MainLayout() {
	const { Header, Content, Footer, Sider } = Layout;
	const location = useLocation();

	const Home = React.lazy(() => import("../pages/Home/Home"));
	const Entry = React.lazy(() => import("../pages/Entry/Entry"));

	const items = [
		{
			key: "home",
			icons: <AppstoreOutlined />,
			label: "Home",
			path: "/home",
		},
		{
			key: "entry",
			icons: <FormOutlined />,
			label: "Entries",
			path: "/entry",
		},
	];

	const text = <span>Options</span>;
	const content = (
		<Menu mode="inline">
			<Menu.Item key={"logout"}>
				<p>Logout</p>
			</Menu.Item>
		</Menu>
	);

	const {
		token: { colorBgContainer, borderRadiusLG },
	} = theme.useToken();

	return (
		<Layout hasSider>
			<Sider
				style={{
					overflow: "auto",
					height: "100vh",
					position: "fixed",
					left: 0,
					top: 0,
					bottom: 0,
				}}>
				<div className="demo-logo-vertical" />
				<Menu
					theme="dark"
					mode="inline"
					defaultSelectedKeys={["home"]}
					selectedKeys={[
						items.find((item) => item.path === location.pathname)?.key,
					]}>
					{items.map((item) => (
						<Menu.Item key={item.key} icon={item.icons}>
							<Link to={item.path}>{item.label}</Link>
						</Menu.Item>
					))}
				</Menu>
			</Sider>
			<Layout
				style={{
					marginLeft: 200,
				}}>
				<Header
					style={{
						padding: 0,
						background: "#EEEEEE",
					}}>
					<div className="float-right mr-8">
						<Popover placement="bottomLeft" title={text} content={content}>
							<UserOutlined />
						</Popover>
					</div>
				</Header>
				<Content
					// className="h-auto"
					style={{
						height: "100%",
					}}>
					<div className="bg-gray-100">
						<React.Suspense fallback={<Loader />}>
							<Routes>
								<Route path="/home" element={<Home />} />
								<Route path="/entry" element={<Entry />} />
							</Routes>
						</React.Suspense>
					</div>
				</Content>
				<Footer
					style={{
						textAlign: "center",
					}}>
					Design ©{new Date().getFullYear()} Created by Suvhradip
				</Footer>
			</Layout>
		</Layout>
	);
}

export default MainLayout;
